import React from "react"
import styled from "styled-components"
import Wrapper, { StyledWrapper } from "./wrapper"
import SignupButton from "./signup-button"

export const CtaBottomBg = styled.div`
  padding: 40px 0;
  background: #53489c;

  @media screen and (min-width: 1024px) {
    padding: 88px 0;
  }
`

export const CtaTitle = styled.h3`
  text-align: center;
  font-size: 32px;
  margin-bottom: 32px;
  color: #fff;
  font-weight: 300;
`

export const CtaGroupButton = styled.div`
  text-align: center;
`

export default function CtaBottom() {
  return (
    <CtaBottomBg>
      <Wrapper>
        <CtaTitle>¿Listo para empezar?</CtaTitle>
        <CtaGroupButton>
          <SignupButton large />
        </CtaGroupButton>
      </Wrapper>
    </CtaBottomBg>
  )
}
