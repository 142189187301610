import React from "react"
import { StyledWrapper } from "./wrapper"
import styled from "styled-components"
import IconApi from "../../static/images/features/api.svg"
import IconFlexibility from "../../static/images/features/flexibility.svg"
import IconScalability from "../../static/images/features/scalability.svg"

const Section = styled.section`
  padding: 40px 0;
  background-color: #f7f9fc;

  @media screen and (min-width: 1024px) {
    padding: 64px 0;
  }
`

const FeatureWrapper = styled(StyledWrapper)`
  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-around;
  }
`

const Feature = styled.div`
  text-align: center;
  margin-bottom: 48px;
  flex-basis: 400px;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    margin-bottom: 8px;
  }

  p {
    line-height: 25px;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 0;

    p {
      padding: 0 16px;
    }
  }
`

const FeatureTitle = styled.h1`
  margin-bottom: 8px;
  font-size: 21px;
  font-weight: 300;
  color: ${props => props.theme.colors.seaSerpent};
`

const Features = () => (
  <Section>
    <FeatureWrapper>
      <Feature>
        <IconApi />
        <FeatureTitle>Creado para desarrolladores</FeatureTitle>
        <p>
          Nos basamos en un concepto "API first" enfocándonos en la simplicidad
          y estándares
        </p>
      </Feature>
      <Feature>
        <IconFlexibility />
        <FeatureTitle>Flexibilidad en mente</FeatureTitle>
        <p>
          Servicio flexible y dinámico que se adapta a cualquier tipo de empresa
          o producto financiero
        </p>
      </Feature>
      <Feature>
        <IconScalability />
        <FeatureTitle>Diseñado para la escalabilidad</FeatureTitle>
        <p>
          Infraestructura diseñada para soportar miles de clientes y operaciones
        </p>
      </Feature>
    </FeatureWrapper>
  </Section>
)

export default Features
