import React from "react"
import Wrapper from "./wrapper"
import styled, { css } from "styled-components"

export const StyledCompanies = styled.div`
  padding: 40px 0;

  @media screen and (min-width: 1024px) {
    padding: 48px 0;
  }
`

export const CompaniesTitle = styled.h3`
  color: #4b4b4b;
  font-size: 18px;
  font-weight: 100;
  margin-bottom: 40px;
  text-align: center;
`

export const contentScroll = css`
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  display: flex;
  flex-direction: row;
  -webkit-appearance: none;

  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const CompaniesBrands = styled.div`
  ${contentScroll};

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  img {
    height: 32px;
    opacity: 0.6;
    margin: 0 40px 40px 0;

    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }

    &:last-child {
      margin: 0;
    }
  }
`

const Companies = () => (
  <>
    <StyledCompanies>
      <Wrapper>
        <CompaniesTitle>
          Empresas que ahorran más de 200 horas de trabajo por mes utilizando
          nuestras APIs
        </CompaniesTitle>
        <CompaniesBrands>
          <img
            src="images/companies/cial_dun_and_bradstreet.png"
            alt="CIAL Dun & Bradstreet"
          />
          <img src="images/companies/unifin.png" alt="Unifin" />
          <img src="images/companies/capitaltech.png" alt="CapitalTech" />
          <img src="images/companies/creze.png" alt="Creze" />
          <img src="images/companies/fairplay.png" alt="Fairplay" />
        </CompaniesBrands>
      </Wrapper>
    </StyledCompanies>
  </>
)

export default Companies
