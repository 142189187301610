import React from "react"
import Wrapper from "./wrapper"
import styled from "styled-components"
import SignupButton from "./signup-button"
import PiggyBankIcon from "../../static/images/use-cases/piggy-bank.svg"
import CheckIcon from "../../static/images/use-cases/check.svg"
import CalendarIcon from "../../static/images/use-cases/calendar.svg"

const Section = styled.section`
  padding: 40px 0;
  background-color: ${props => props.theme.colors.liberty};

  @media screen and (min-width: 1024px) {
    padding: 64px 0;
  }
`

const Title = styled.h1`
  color: #fff;
  text-align: center;
  font-weight: 100;
  font-size: 28px;
  margin-bottom: 40px;

  @media screen and (min-width: 1024px) {
    font-size: 32px;
    margin-bottom: 56px;
  }
`

const Description = styled.p`
  /* font-size: 21px; */
`

const Features = styled.div`
  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
  }
`

const Card = styled.div`
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 32px;
  padding: 24px;
  border: 1px solid #ebebeb;
  text-align: center;

  @media screen and (min-width: 1024px) {
    flex-basis: 380px;
    justify-content: space-between;
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }

  img {
    margin-bottom: 16px;
  }

  h2 {
    font-size: 22px;
    margin: 8px 0 8px 0;
  }
`

export const Subtitle = styled.p``

const CallToAction = styled.div`
  text-align: center;
  color: #fff;
  margin: 0;
  font-size: 24px;

  p {
    margin-bottom: 32px;
  }
`

const UseCases = () => (
  <Section>
    <Wrapper>
      <Title>Construye la nueva generación de servicios financieros</Title>
      <Features>
        <Card>
          <PiggyBankIcon width={60} />
          <div>
            <h2>Finanzas personales</h2>
            <p>
              Ayuda a los usuarios a administrar, presupuestar y dar sentido a
              su dinero
            </p>
          </div>
        </Card>
        <Card>
          <CheckIcon width={60} />
          <div>
            <h2>Préstamos</h2>
            <p>
              Ayuda a clientes y empresas a acceder a capital a través de una
              experiencia optimizada
            </p>
          </div>
        </Card>
        <Card>
          <CalendarIcon width={60} />
          <div>
            <h2>Finanzas empresariales</h2>
            <p>Ayuda a las empresas a administrar sus gastos y contabilidad</p>
          </div>
        </Card>
      </Features>
      <CallToAction>
        <p>¿Listo para empezar?</p>
        <SignupButton large />
      </CallToAction>
    </Wrapper>
  </Section>
)

export default UseCases
