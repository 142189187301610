import React from "react"
import Banner from "../components/banner"
import Companies from "../components/companies"
import CtaBottom from "../components/cta-bottom"
import Dashboard from "../components/dashboard"
import Features from "../components/features"
import Integration from "../components/integration"
import Layout from "../components/layout"
import ModalSyntage from "../components/modal-syntage"
import Product from "../components/product"
import UseCases from "../components/use-cases"

export default () => (
  <Layout>
    <ModalSyntage />
    <Banner />
    <Companies />
    <Features />
    <Product />
    <UseCases />
    <Integration />
    <Dashboard />
    <CtaBottom />
  </Layout>
)
