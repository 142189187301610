import React from "react"
import styled, { keyframes } from "styled-components"
import Clipboard from "../../static/images/clipboard.svg"

const dashProgress = keyframes`
  0% {
    stroke-dashoffset: 100;
  }

  100% {
    stroke-dashoffset: 0;
  }
`

const StyledClipboard = styled(Clipboard)`
  .lines {
    animation: ${dashProgress} 5s linear infinite;
  }
`

export default props => <StyledClipboard {...props} />
