import React from "react"
import Wrapper from "./wrapper"
import styled from "styled-components"
import Clipboard from "./clipboard"

const Section = styled.section`
  padding: 40px 0;

  @media screen and (min-width: 1024px) {
    padding: 64px 0;
  }
`

const Title = styled.h1`
  font-size: 28px;
  color: #272822;
  margin-bottom: 40px;

  @media screen and (min-width: 1024px) {
    font-size: 32px;
    margin-bottom: 48px;
  }
`

export const ProductDescription = styled.div`
  text-align: center;

  @media screen and (min-width: 1024px) {
    text-align: left;
    display: flex;
    margin-bottom: 48px;
  }
`

export const ProductDescriptionLeft = styled.div`
  margin-bottom: 48px;

  @media screen and (min-width: 1024px) {
    flex-basis: 50%;
    margin-bottom: 0;
  }
`

const Description = styled.p`
  font-size: 18px;
  margin-bottom: 24px;
  line-height: 25px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const ProductDescriptionRight = styled.div`
  margin-bottom: 48px;

  svg {
    width: 100%;
  }

  @media screen and (min-width: 1024px) {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }

  img {
    width: 100%;
    max-width: 400px;
  }
`

const Features = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`

const FeatureCard = styled.div`
  margin-bottom: 32px;
  padding: 0 16px;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    flex-basis: 50%;
    padding: 0 16px;
    margin-bottom: 0;
  }

  @media screen and (min-width: 1024px) {
    flex-basis: 250px;
    padding: 0;
  }

  h2 {
    margin-bottom: 8px;
  }

  p {
    line-height: 24px;
  }
`

const Product = () => (
  <Section id="productos">
    <Wrapper>
      <ProductDescription>
        <ProductDescriptionLeft>
          <Title>Usa el poder de los datos fiscales en tu negocio</Title>
          <Description>
            Conéctate a nuestras APIs para obtener, en minutos, acceso a todos
            los datos fiscales de tus clientes directamente desde el Servicio de
            Administración Tributaria (SAT).
          </Description>
          <Description>
            Concéntrate en el desarrollo de tu producto y nosotros nos
            encargamos de la complejidad de la extracción, transformación,
            integración y disponibilidad de los datos.
          </Description>
        </ProductDescriptionLeft>
        <ProductDescriptionRight>
          <Clipboard />
        </ProductDescriptionRight>
      </ProductDescription>
      <Features>
        <FeatureCard>
          <h2>Facturas</h2>
          <p>
            Facturas emitidas y recibidas, con su metadata y CFDI (XML y PDF)
          </p>
        </FeatureCard>
        <FeatureCard>
          <h2>Declaraciones</h2>
          <p>Declaraciones mensuales, anuales, pagos, cumplimiento, etc.</p>
        </FeatureCard>
        {/* <FeatureCard>
          <h2>Validaciones</h2>
          <p>Validación de RFC, CIEC y e.firma (certificado)</p>
        </FeatureCard> */}
        <FeatureCard>
          <h2>Catálogos</h2>
          <p>Catálagos de productos, servicios, y otros items del SAT</p>
        </FeatureCard>
      </Features>
    </Wrapper>
  </Section>
)

export default Product
