import React from "react"
import styled from "styled-components"
import Wrapper, { StyledWrapper } from "./wrapper"

export const DashboardBg = styled.div`
  padding: 40px 0;
  background: #f7f9fc;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;

  @media screen and (min-width: 1024px) {
    padding: 62px 0;
  }
`

export const DashboardTitle = styled.h3`
  text-align: center;
  font-size: 28px;
  margin-bottom: 24px;
  font-weight: 300;

  @media screen and (min-width: 1024px) {
    font-size: 32px;
    margin-bottom: 32px;
  }
`

export const DashboardDescription = styled.div`
  max-width: 800px;
  margin: 0 auto 32px;
  text-align: center;
  line-height: 25px;
`

export const DashboardBoxImage = styled.div`
  padding: 8px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  max-width: 800px;
  margin: 0 auto;
`

export const DashboardImage = styled.img`
  width: 100%;
  border-radius: 3px;
`

export default function Dashboard() {
  return (
    <DashboardBg>
      <Wrapper>
        <DashboardTitle>
          Visualiza todos los datos en el dashboard
        </DashboardTitle>
        <DashboardDescription>
          Tenemos una aplicación web que le permite navegar la información
          extraída de los contribuyentes, proporcionando a desarrolladores y
          analistas una vista rápida de la información.
        </DashboardDescription>
        <DashboardBoxImage>
          <DashboardImage src="images/app.png" />
        </DashboardBoxImage>
      </Wrapper>
    </DashboardBg>
  )
}
