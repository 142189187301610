import React, { useEffect } from "react"
import styled from "styled-components"

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 40;

  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContent = styled.div`
  padding: 0 24px;
`

const ModalHead = styled.div`
  text-align: right;
  margin-right: 4px;

  button {
    background: none;
    border: none;
  }

  svg {
    cursor: pointer;
  }
`

const ModalBody = styled.div`
  background: #fff;
  padding: 32px;
  border-radius: 12px;
  max-width: 500px;
`

const SyntageTitle = styled.div`
  font-size: 20px;
  margin-bottom: 16px;
`

const SyntageDescription = styled.div`
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 16px;
`

export default function ModalSyntage() {
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    if (!localStorage.getItem("modal-syntage")) {
      setOpen(true)
    }
  }, [])

  if (!open) {
    return null
  }

  function handleClose() {
    localStorage.setItem("modal-syntage", "true")
    setOpen(false)
  }

  return (
    <Modal>
      <ModalContent>
        <ModalHead>
          <button onClick={() => handleClose()}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.21967 4.21967C4.51256 3.92678 4.98744 3.92678 5.28033 4.21967L12 10.9393L18.7197 4.21967C19.0126 3.92678 19.4874 3.92678 19.7803 4.21967C20.0732 4.51256 20.0732 4.98744 19.7803 5.28033L13.0607 12L19.7803 18.7197C20.0732 19.0126 20.0732 19.4874 19.7803 19.7803C19.4874 20.0732 19.0126 20.0732 18.7197 19.7803L12 13.0607L5.28033 19.7803C4.98744 20.0732 4.51256 20.0732 4.21967 19.7803C3.92678 19.4874 3.92678 19.0126 4.21967 18.7197L10.9393 12L4.21967 5.28033C3.92678 4.98744 3.92678 4.51256 4.21967 4.21967Z"
                fill="#FFF"
              />
            </svg>
          </button>
        </ModalHead>

        <ModalBody>
          <img
            src="https://syntage.com/logo.svg"
            style={{ marginBottom: 16, height: 32 }}
          />
          <SyntageTitle>Satws evoluciona a Syntage</SyntageTitle>
          <SyntageDescription>
            Syntage busca ampliar sus fuentes de información para volverse una
            plataforma más poderosa de datos abiertos end-to-end. Satws es su
            primer producto enfocado 100% en datos del SAT. Conoce más en la
            primer publicación de nuestro{" "}
            <a
              href="https://syntage.com/es/blog/somos-syntage?utm_source=modal&utm_id=syntage-satws"
              target="_blank"
            >
              blog
            </a>
            .
          </SyntageDescription>
          <b>Bienvenido, Syntage.</b>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
