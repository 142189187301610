import React from "react"
import styled from "styled-components"
import Wrapper, { StyledWrapper } from "./wrapper"

export const StyledIntegration = styled.div`
  padding: 40px 0;

  @media screen and (min-width: 1024px) {
    padding: 64px 0;
  }
`

export const IntegrationTitle = styled.h4`
  margin: 0;
  font-size: 28px;
  font-weight: 300;
  text-align: center;
  padding: 0 0 40px 0;

  @media screen and (min-width: 1024px) {
    font-size: 32px;
    padding: 0 0 56px 0;
  }
`

export const IntegrationBox = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
  }
`

export const IntegrationBoxImages = styled.div`
  flex-basis: 100%;
  margin-bottom: 32px;

  @media screen and (min-width: 768px) {
    flex-basis: 50%;
    margin-bottom: 0;
  }

  @media screen and (min-width: 1024px) {
    flex-basis: 50%;
  }

  img {
    width: 100%;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (min-width: 1024px) {
      width: inherit;
      max-width: 600px;
    }
  }
`

export const IntegrationBoxDescription = styled.div`
  > div {
    line-height: 25px;
    text-align: center;

    @media screen and (min-width: 768px) {
      text-align: left;
    }
  }

  @media screen and (min-width: 768px) {
    flex: 1;
    padding-left: 48px;
    text-align: left;
  }
`

export const IntegrationDetailsTitle = styled.h4`
  font-size: 22px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 24px;

  @media screen and (min-width: 768px) {
    text-align: left;
  }
`

export const IntegrationDetailsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    margin: 16px 0;
    display: flex;

    @media screen and (min-width: 1024px) {
      padding: 16px 0;
      flex-direction: row;
      /* justify-content: space-between; */
    }
  }
`

export const IntegrationDetailsListCount = styled.div`
  span {
    width: 32px;
    height: 32px;
    display: inline-flex;
    border: 1px solid #5ac3c3;
    border-radius: 50%;
    margin-right: 16px;
    color: #5ac3c3;
    align-items: center;
    justify-content: center;
  }
`

export const IntegrationDetailsListDescription = styled.div`
  div {
    font-size: 18px;
    color: #5ac3c3;
    margin-bottom: 8px;
  }
`

export default function Integration() {
  return (
    <StyledIntegration>
      <Wrapper>
        <IntegrationTitle>
          Integración con el SAT en pocas lineas de código
        </IntegrationTitle>
        <IntegrationBox>
          <IntegrationBoxImages>
            <img src="images/editor.png" draggable={false} />
            {/* <img src="https://via.placeholder.com/600x80" /> */}
          </IntegrationBoxImages>
          <IntegrationBoxDescription>
            <IntegrationDetailsTitle>
              Rápida configuración y fácil integración
            </IntegrationDetailsTitle>
            <div>
              Tenemos un conjunto robusto de APIs y documentación extensa para
              que puedas pasar menos tiempo integrando datos fiscales a tu
              aplicación y más tiempo desarrollando tu producto. Ésto es lo que
              hay que hacer:
            </div>
            <IntegrationDetailsList>
              <li>
                <IntegrationDetailsListCount>
                  <span>1</span>
                </IntegrationDetailsListCount>
                <IntegrationDetailsListDescription>
                  <div>Activa tu cuenta</div>
                  <span>
                    Después de crear tu cuenta, checa tu correo y haz click en
                    el link.
                  </span>
                </IntegrationDetailsListDescription>
              </li>
              <li>
                <IntegrationDetailsListCount>
                  <span>2</span>
                </IntegrationDetailsListCount>
                <IntegrationDetailsListDescription>
                  <div>Ingresa credenciales</div>
                  <span>
                    Registra las credenciales CIEC o e.firma de tus clientes y
                    confirma que sean válidas.
                  </span>
                </IntegrationDetailsListDescription>
              </li>
              <li>
                <IntegrationDetailsListCount>
                  <span>3</span>
                </IntegrationDetailsListCount>
                <IntegrationDetailsListDescription>
                  <div>Recibe la información</div>
                  <span>
                    En pocos minutos tendrás disponible la información del
                    contribuyente registrado, incluyendo facturas,
                    declaraciones, constancias fiscales, opiniones de
                    cumplimiento, entre otros.
                  </span>
                </IntegrationDetailsListDescription>
              </li>
            </IntegrationDetailsList>
          </IntegrationBoxDescription>
        </IntegrationBox>
      </Wrapper>
    </StyledIntegration>
  )
}
